<template>
  <div class="chart"></div>
</template>
<script>
  import echarts from 'echarts';
  import resize from './mixins/resize';

  require('echarts/theme/roma'); // echarts theme

  export default {
    name: 'CentPieChart',
    data() {
      return {
        chart: null
      };
    },
    props: {
      customerCount: {
        type: Array,
        required: true
      }
    },
    mixins: [resize],
    mounted() {
      this.$nextTick(() => {
        this.initChart();
      });
    },
    beforeDestroy() {
      if (!this.chart) {
        return;
      }
      this.chart.dispose();
      this.chart = null;
    },
    methods: {
      initChart() {
        this.chart = echarts.init(this.$el, 'macarons');
        this.chart.setOption({
          title: {
            text: '客人类型数量',
            left: 'center'
          },
          tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {c} ({d}%)'
          },
          toolbox: {
            feature: {
              dataView: {show: true, readOnly: false},
              restore: {show: true},
              saveAsImage: {show: true}
            }
          },
          legend: {
            orient: 'vertical',
            left: 'left',
            data: this.customerCount.map(n => n.name)
          },
          series: [
            {
              name: '客人类型',
              type: 'pie',
              radius: '55%',
              center: ['50%', '60%'],
              data: this.customerCount,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        });
      }
    },
    watch: {
      // 正确给 cData 赋值的 方法
      customerCount: function(newVal, oldVal) {
        this.cData = newVal; // newVal即是chartData
        newVal && this.initChart(); // newVal存在的话执行drawChar函数
      }
    }
  };
</script>
<style scoped lang="less">
  .chart{margin-top:30px;height:280px;}
</style>
