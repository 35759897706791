<template>
  <div class="main">
    <template>
      <div class="report-item">
        <line-chart :chart-data="orderMoney.lineChartXData" :y-data="orderMoney.lineChartYData"/>
      </div>
      <div class="report-item">
        <div class="hd cl">
          <div class="fl time-wrap">
            <div class="label">{{$t('Years')}}：</div>
            <el-select class="fl select" v-model="orderYear" size="small" :placeholder="$t('PleaseChoose')">
              <el-option v-for="item in getYearForFive()"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="fl time-wrap">
            <div class="label">{{$t('Month')}}：</div>
            <el-select class="fl select" v-model="orderMonth" size="small" :placeholder="$t('PleaseChoose')">
              <el-option v-for="item in getMonthArray()"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </div>
          所有訂單下單金額／訂單總數＝訂單的平均價格<br>
          {{ orderAvg.sumMoney }}／{{ orderAvg.number }}＝{{ orderAvg.avg }}
        </div>
      </div>
      <el-row :gutter="20">
        <el-col :span="12">
          <div class="grid-content bg-purple">
            <div class="report-item">
              <pie-chart :product-count="productCount"/>
            </div>
            <div class="report-item m-t">
              <div class="hd cl">
                <div class="fl time-wrap">
                  <div class="label">{{$t('Time')}}：</div>
                  <el-select class="fl select" v-model="customerType" size="small" :placeholder="$t('PleaseChoose')">
                    <el-option v-for="item in customerOptions"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value">
                    </el-option>
                  </el-select>
                </div>
              </div>
              <cent-pie-chart :customer-count="customerCount"/>
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content bg-purple">
            <div class="report-item m-t">
              <div class="hd cl">
                <div class="fl time-wrap">
                  <div class="label">{{$t('Years')}}：</div>
                  <el-select class="fl select" v-model="year" size="small" :placeholder="$t('PleaseChoose')">
                    <el-option v-for="item in getYearForFive()"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value">
                    </el-option>
                  </el-select>
                </div>
                <div class="fl time-wrap">
                  <div class="label">{{$t('Month')}}：</div>
                  <el-select class="fl select" v-model="month" size="small" :placeholder="$t('PleaseChoose')">
                    <el-option v-for="item in getMonthArray()"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value">
                    </el-option>
                  </el-select>
                </div>
              </div>
              <bar-chart :x-data="hotProduct.xData" :y-data="hotProduct.yData"/>
            </div>

            <div class="report-item m-t">
              <div class="hd cl">
                <div class="fl time-wrap">
                  <div class="label">{{$t('Time')}}：</div>
                  <el-select class="fl select" v-model="addDate" size="small" :placeholder="$t('PleaseChoose')">
                    <el-option v-for="item in timeOptions"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value">
                    </el-option>
                  </el-select>
                </div>
              </div>
              <line-and-barChart :name="orderAndCustomerCount.name" :order-count="orderAndCustomerCount.orderCount" :customer-count="orderAndCustomerCount.customerCount"/>
            </div>
          </div>
        </el-col>
      </el-row>
      <!--
      <div class="report-item m-t">
        <div class="hd cl">
          <div class="fl time-wrap">
            <div class="label">{{$t('Time')}}：</div>
            <el-select class="fl select" v-model="time" size="small" :placeholder="$t('PleaseChoose')">
              <el-option v-for="item in timeOptions"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </div>
          <a class="fr btn" href="javascript:;">{{$t('Export excel')}}</a>
          <div class="tit">{{$t('The turnover')}}</div>
        </div>

        <cent-pie-chart :customer-count="customerCount"/>
        <bar-chart/>
      </div>
      <div class="report-item m-t">
        <div class="hd cl">
          <div class="fl time-wrap">
            <div class="label">{{$t('Time')}}：</div>
            <el-select class="fl select" v-model="time" size="small" :placeholder="$t('PleaseChoose')">
              <el-option v-for="item in timeOptions"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </div>
          <a class="fr btn" href="javascript:;">{{$t('Export excel')}}</a>
          <div class="tit">{{$t('Product heat')}}</div>
        </div>
        <div class="table border">
          <table>
            <thead>
            <tr>
              <th width="110">{{$t('ProductNumber')}}</th>
              <th>{{$t('ProductName')}}</th>
              <th width="180">{{$t('User views')}}（{{$t('Clicks')}}）</th>
            </tr>
            </thead>
            <tbody class="tbody">
            <tr v-for="i in 3" :key="i">
              <td>p0001</td>
              <td>日本單車遊宣傳</td>
              <td>1390</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>-->
    </template>
  </div>
</template>
<script>
  import BarChart from './components/BarChart';
  import PieChart from './components/PieChart';
  import CentPieChart from './components/CentPieChart';
  import LineChart from './components/LineChart';
  import LineAndBarChart from './components/LineAndBarChart';
  import {getData, getHotProductData, getCustomerData, getOrderAndCustomerCount, getOrderCountAndMoney} from '@/api/admin-data';
  import dateUtil from '@/mixins/dateUtil';

  export default {
    name: 'AdminDataReport',
    data() {
      return {
        timeOptions: [
          {value: 1, label: '本日'},
          {value: 7, label: '近7天'},
          {value: 30, label: '近30天'}
        ],
        customerOptions: [
          {value: 7, label: '近7天'},
          {value: 30, label: '近30天'}
        ],
        time: '',
        orderMoney: {
          lineChartXData: [],
          lineChartYData: []
        },
        productCount: [],
        customerCount: [],
        customerType: 7,
        hotProduct: {
          xData: [],
          yData: []
        },
        orderAndCustomerCount: {
          name: [],
          orderCount: [],
          customerCount: []
        },
        orderAvg: {
          sumMoney: 0,
          number: 0,
          avg: 0
        },
        addDate: 1,
        orderYear: new Date().getFullYear(),
        orderMonth: '',
        year: new Date().getFullYear(),
        month: ''
      };
    },
    components: {BarChart, LineChart, PieChart, CentPieChart, LineAndBarChart},
    mixins: [dateUtil],
    created() {
      this.getData();
      this.getHotProductData();
      this.getCustomerData();
      this.getOrderAndCustomerCount();
      this.getOrderCountAndMoney();
    },
    methods: {
      getData() {
        getData().then(res => {
          var {value} = res;
          // 近30天下单金额
          let orderMoney = value.orderMoney;
          this.orderMoney.lineChartYData = Object.keys(orderMoney).map(function(key) {
            return dateUtil.methods.formatDate(key);
          });
          this.orderMoney.lineChartXData = Object.keys(orderMoney).map(function(key) {
            return orderMoney[key];
          });
          // 产品数量统计
          this.productCount = value.productCount;
          // 多語言轉換
          this.productCount.forEach(value => {
            value.name = this.$t(value.name);
          });
        });
      },
      getCustomerData() {
        let data = {customerType: this.customerType};
        getCustomerData(data).then(res => {
          var {value} = res;
          // 客户类型统计
          this.customerCount = value;
          // 多語言轉換
          this.customerCount.forEach(value => {
            value.name = this.$t(value.name);
          });
        });
      },
      getHotProductData() {
        let data = {addDate: this.year + this.month};
        getHotProductData(data).then(res => {
          var {value} = res;
          // 前10热门产品
          let hotProduct = value;
          this.hotProduct.xData = hotProduct.map(n => n.name);
          this.hotProduct.yData = hotProduct.map(n => n.value);
        });
      },
      getOrderAndCustomerCount() {
        let data = {addDate: this.addDate};
        getOrderAndCustomerCount(data).then(res => {
          var {value} = res;
          // 各产品订单和客人数量
          let orderAndCustomerCount = value;
          this.orderAndCustomerCount.name = orderAndCustomerCount.map(n => n.name);
          this.orderAndCustomerCount.orderCount = orderAndCustomerCount.map(n => n.value);
          this.orderAndCustomerCount.customerCount = orderAndCustomerCount.map(n => n.number);
        });
      },
      getOrderCountAndMoney() {
        let data = {addDate: this.orderYear + this.orderMonth};
        getOrderCountAndMoney(data).then(res => {
          var {value} = res;
          // 訂單總額，數量，平均值
          this.orderAvg = value;
        });
      },
      // 获取最近5年年份数组对象
      getYearForFive() {
        var year = new Date().getFullYear();
        var yaerList = [];
        for (var i = 0; i < 5; i++) {
          var dict = {value: (year - i), label: (year - i) + '年'};
          yaerList.push(dict);
        }
        return yaerList;
      },
      // 获取12月月份数组
      getMonthArray() {
        var monthList = [];
        for (var i = 1; i <= 12; i++) {
          var dict;
          if (i < 10) {
            dict = {value: '-0' + i, label: i + '月'};
          } else {
            dict = {value: '-' + i, label: i + '月'};
          }
          monthList.push(dict);
        }
        monthList.unshift({value: '', label: '請選擇'});
        return monthList;
      }
    },
    watch: {
      customerType: function (value) {
        this.getCustomerData();
      },
      year: function (value) {
        this.getHotProductData();
      },
      month: function (value) {
        this.getHotProductData();
      },
      addDate: function (value) {
        this.getOrderAndCustomerCount();
      },
      orderYear: function (value) {
        this.getOrderCountAndMoney();
      },
      orderMonth: function (value) {
        this.getOrderCountAndMoney();
      }
    }
  };
</script>
<style scoped lang="less">
  @import "../../../assets/style/table";
  .main{
    .m-t{margin-top:40px;}
    .tbody{
      td{vertical-align:top;}
    }
  }
  .report-item{
    .hd{
      .tit{text-align:center;font-size:24px;}
      .btn{display:inline-block;margin-top:3px;margin-left:8px;padding:0 8px;line-height:28px;color:#ff6f61;font-size:14px;font-weight:normal;border:1px solid #ff6f61;border-radius:4px;}
    }
  }
  .time-wrap{
    .label{float:left;line-height:32px;color:#321908;font-size:16px;}
    .select{width:128px;vertical-align:top;}
  }
</style>
