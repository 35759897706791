<template>
  <div class="chart"></div>
</template>
<script>
  import echarts from 'echarts';
  import resize from './mixins/resize';

  require('echarts/theme/roma'); // echarts theme

  export default {
    name: 'LineAndBarChart',
    props: {
      name: {
        type: Array,
        required: true
      },
      orderCount: {
        type: Array,
        required: true
      },
      customerCount: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
        chart: null
      };
    },
    mixins: [resize],
    mounted() {
      this.$nextTick(() => {
        this.initChart();
      });
    },
    beforeDestroy() {
      if (!this.chart) {
        return;
      }
      this.chart.dispose();
      this.chart = null;
    },
    methods: {
      initChart() {
        this.chart = echarts.init(this.$el, 'macarons');
        this.setOptions(this.chartData);
      },
      setOptions(expectedData = {}) {
        this.chart.setOption({
          title: {
            text: '平台單產品成交訂單與客戶數量'
            // subtext: '纯属虚构'
          },
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            data: ['客戶人數', '訂單數量']
          },
          toolbox: {
            show: true,
            feature: {
              dataView: {readOnly: false},
              magicType: {type: ['line', 'bar']},
              restore: {},
              saveAsImage: {}
            }
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            data: this.name
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              formatter: '{value} °C'
            }
          },
          series: [
            {
              name: '客戶人數',
              type: 'line',
              data: this.customerCount,
              markPoint: {
                data: [
                  {type: 'max', name: '最大值'},
                  {type: 'min', name: '最小值'}
                ]
              },
              markLine: {
                data: [
                  {type: 'average', name: '平均值'}
                ]
              }
            },
            {
              name: '訂單數量',
              type: 'line',
              data: this.orderCount,
              markPoint: {
                data: [
                  {type: 'max', name: '最大值'},
                  {type: 'min', name: '最小值'}
                ]
              },
              markLine: {
                data: [
                  {type: 'average', name: '平均值'},
                  [{
                    symbol: 'none',
                    x: '90%',
                    yAxis: 'max'
                  }, {
                    symbol: 'circle',
                    label: {
                      position: 'start',
                      formatter: '最大值'
                    },
                    type: 'max',
                    name: '最高点'
                  }]
                ]
              }
            }
          ]
        });
      }
    },
    watch: {
      // 正确给 cData 赋值的 方法
      name: function(newVal, oldVal) {
        this.name = newVal; // newVal即是chartData
        newVal && this.initChart(); // newVal存在的话执行drawChar函数
      },
      orderCount: function(newVal, oldVal) {
        this.orderCount = newVal; // newVal即是chartData
        newVal && this.initChart(); // newVal存在的话执行drawChar函数
      },
      customerCount: function(newVal, oldVal) {
        this.customerCount = newVal; // newVal即是chartData
        newVal && this.initChart(); // newVal存在的话执行drawChar函数
      }
    }
  };
</script>
<style scoped lang="less">
  .chart{margin-top:10px;height:280px;}
</style>
