<template>
  <div class="chart"></div>
</template>
<script>
  import echarts from 'echarts';
  import resize from './mixins/resize';

  require('echarts/theme/roma'); // echarts theme

  export default {
    name: 'LineChart',
    props: {
      chartData: {
        type: Array,
        required: true
      },
      yData: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
        chart: null
      };
    },
    mixins: [resize],
    mounted() {
      this.$nextTick(() => {
        this.initChart();
      });
    },
    beforeDestroy() {
      if (!this.chart) {
        return;
      }
      this.chart.dispose();
      this.chart = null;
    },
    methods: {
      initChart() {
        this.chart = echarts.init(this.$el, 'macarons');
        this.setOptions(this.chartData);
      },
      setOptions(expectedData = {}) {
        this.chart.setOption({
          title: {
            text: '近30天產品訂購總金額（下單金額）',
            left: 'center'
          },
          toolbox: {
            show: true,
            feature: {
              dataView: {readOnly: false},
              magicType: {type: ['line', 'bar']},
              restore: {},
              saveAsImage: {}
            }
          },
          xAxis: {
            data: this.yData,
            boundaryGap: false,
            axisTick: {
              show: false
            },
            axisLabel: {
              interval: 0,
              rotate: 40
            }
          },
          grid: {
            left: 10,
            right: 10,
            bottom: 20,
            top: 30,
            containLabel: true
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross'
            },
            padding: [5, 10]
          },
          yAxis: {
            axisTick: {
              show: false
            }
          },
          legend: {
            data: ['expected', 'actual']
          },
          series: [{
            name: '成交額',
            itemStyle: {
              normal: {
                color: '#ff6f61',
                lineStyle: {
                  color: '#ff6f61',
                  width: 2
                }
              }
            },
            smooth: true,
            type: 'line',
            data: expectedData,
            animationDuration: 2000,
            animationEasing: 'cubicInOut'
          }]
        });
      }
    },
    watch: {
      chartData: {
        deep: true,
        handler(val) {
          this.setOptions(val);
        }
      }
    }
  };
</script>
<style scoped lang="less">
  .chart{margin-top:10px;height:280px;}
</style>
