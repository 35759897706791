import request from '@/utils/request';
import {getLocale} from '../utils/language';

const language = getLocale() || 'zh-hk';

// 获取商户数据报告
export function getData() {
  return request({
    url: `/admin/data/getData`,
    baseURL: '/v2',
    method: 'get'
  });
}
// 获取各产品订单和客人数量
export function getOrderAndCustomerCount(data) {
  return request({
    url: `/admin/data/getOrderAndCustomerCount/${language}`,
    baseURL: '/v2',
    method: 'get',
    params: data
  });
}

// 获取客户数据报告
export function getCustomerData(data) {
  return request({
    url: `/admin/data/getCustomerData`,
    baseURL: '/v2',
    method: 'post',
    data
  });
}

// 获取热门产品数据
export function getHotProductData(data) {
  return request({
    url: `/admin/data/getHotProductData/${language}`,
    baseURL: '/v2',
    method: 'get',
    params: data
  });
}

// 获取訂單總數與總額求平均值
export function getOrderCountAndMoney(data) {
  return request({
    url: `/admin/data/getOrderCountAndMoney`,
    baseURL: '/v2',
    method: 'post',
    data
  });
}
